import React, { useEffect, useRef, useState } from 'react';
import areah01 from '../../assets/areah01.png'; // 돌고래 이미지 경로
import areah02 from '../../assets/areah02.png'; // 화살표 이미지 경로
import areah03 from '../../assets/areah03.png'; // 이미지 1 경로
import areah04 from '../../assets/areah04.png'; // 이미지 2 경로
import areah05 from '../../assets/areah05.png'; // 이미지 3 경로
import areah06 from '../../assets/areah06.png'; // 이미지 4 경로

const AreaH = () => {
  const [isVisible, setIsVisible] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    const currentTextRef = textRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      {
        threshold: 0.3,
      }
    );

    if (currentTextRef) {
      observer.observe(currentTextRef);
    }

    return () => {
      if (currentTextRef) {
        observer.unobserve(currentTextRef);
      }
    };
  }, []);

  return (
    <div className="pt-24"> {/* 상단 간격을 위한 padding-top */}
      <div
        ref={textRef}
        className={`relative text-center space-y-4 mb-12 transition-opacity duration-1000 ease-out transform ${
          isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
        }`}
      >
        <span className="inline-block bg-[#58a05e] text-white px-4 py-2 rounded-full text-sm font-bold mb-6">
          POINT 01
        </span>
        <h2
          className="text-2xl md:text-4xl font-bold mb-8"
          style={{ lineHeight: 'calc(1em + 8px)' }}
        >
          바둑이 처음인가요?<br />
          <span className="text-[#58a05e]">두고팡</span>과 함께 배워보아요.
        </h2>
        <p className="text-ms md:text-xl mb-8">
          입문부터 고급까지 단계별 실력완성<br />
          스스로 재미있게 바둑을 배우고 문제풀이까지
        </p>
      </div>

      {/* 이미지 배치 */}
      <div className="relative w-full max-w-[1160px] px-8 md:px-12 mx-auto">
        {/* 화살표 이미지 */}
        <img
          src={areah02}
          alt="Arrow with steps"
          className="w-full h-auto"
        />
        {/* 돌고래 이미지 */}
        <img
          src={areah01}
          alt="Dolphin"
          className="absolute top-1/2 transform -translate-y-1/2 w-[35%] max-w-[300px] h-auto left-[1%] sm:w-[23%] sm:left-[4%] md:w-[25%] lg:w-[25%] xl:w-[25%]"
          style={{ minWidth: '100px' }}
        />
      </div>

      {/* 2x2 그리드 이미지 배치 - 모바일에서는 1x4로 배치 */}
      <div className="relative grid grid-cols-1 md:grid-cols-2 w-full max-w-[1044px] gap-4 mt-8 mx-auto px-0 md:px-12 mb-16">
        <img src={areah03} alt="Step 1" className="h-auto mx-auto w-[70%] md:w-full" />
        <img src={areah04} alt="Step 2" className="h-auto mx-auto w-[70%] md:w-full" />
        <img src={areah05} alt="Step 3" className="h-auto mx-auto w-[70%] md:w-full" />
        <img src={areah06} alt="Step 4" className="h-auto mx-auto w-[70%] md:w-full" />
      </div>
    </div>
  );
};

export default AreaH;