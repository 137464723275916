import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'; // 'useLocation'을 제거했습니다.
import logo from '../../assets/logo.png';

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => setIsOpen((prev) => !prev);
  const closeMenu = () => setIsOpen(false);

  // 고정된 메뉴 항목
  const fixedMenuItems = [
    { href: '/teacher', label: '두고팡 프리미엄 🏫', className: 'text-[#905D94] font-black' },
    // { href: '#curriculum', label: '커리큘럼' }, // 임시 주석 처리
    // { href: '#method', label: '학습방법' }, // 임시 주석 처리
    { href: '/pay', label: '이용금액' },
    { href: '/service', label: '고객센터' },
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogoClick = () => {
    window.scrollTo(0, 0); // 페이지 최상단으로 스크롤
  };

  return (
    <nav className="fixed top-0 left-0 w-full z-50 bg-white shadow">
      <div className="flex items-center justify-between px-8 py-4 max-w-[1024px] mx-auto">
        <Link to="/" onClick={handleLogoClick}>
          <img src={logo} alt="Company Logo" className="h-12" />
        </Link>
        {/* Desktop Navigation */}
        <ul className="hidden md:flex space-x-8 text-md">
          {fixedMenuItems.map(({ href, label, className = '' }) => (
            <li key={href}>
              <a href={href} className={`hover:text-[#f3cf68] ${className}`}>{label}</a>
            </li>
          ))}
        </ul>
        <div className="hidden md:flex space-x-4">
          <a href="https://student.dogopang.com/" target="_blank" rel="noopener noreferrer">
            <button className="border border-black px-4 py-2 rounded-md hover:bg-gray-100">로그인</button>
          </a>
          <a href="https://freetrial.dogopang.com/">
            <button className="border border-black bg-black text-white px-4 py-2 rounded-md hover:bg-gray-800">무료체험하기</button>
          </a>
        </div>

        {/* Mobile Menu Toggle Button */}
        <div className="md:hidden">
          <button onClick={toggleMenu} className="relative top-[5px]">
            <div className="space-y-2">
              <span className={`block w-6 h-0.5 bg-black transform transition-transform ${isOpen ? 'rotate-45 translate-y-2' : ''}`}></span>
              <span className={`block w-6 h-0.5 bg-black transition-opacity ${isOpen ? 'opacity-0' : ''}`}></span>
              <span className={`block w-6 h-0.5 bg-black transform transition-transform ${isOpen ? '-rotate-45 -translate-y-2' : ''}`}></span>
            </div>
          </button>
        </div>

        {/* Mobile Menu */}
        <div ref={menuRef} className={`fixed top-0 left-0 w-full h-[380px] max-h-[80vh] bg-white z-40 flex flex-col items-center justify-center transform ${isOpen ? 'translate-y-0 shadow-lg' : '-translate-y-full'} transition-transform duration-300 ease-in-out md:hidden`}>
          <button onClick={closeMenu} className="absolute top-[18px] right-[33px] text-black text-4xl">
            &times;
          </button>
          <ul className="space-y-4 text-lg text-center mt-[30px]">
            {fixedMenuItems.map(({ href, label, className = '' }) => (
              <li key={href}>
                <a href={href} className={`hover:text-[#f3cf68] ${className}`} onClick={closeMenu}>{label}</a>
              </li>
            ))}
          </ul>
          <div className="mt-[20px] space-y-4 w-full flex flex-col items-center">
            <a href="https://student.dogopang.com/">
              <button className="border border-black px-12 py-2 rounded-md hover:bg-gray-100">로그인</button>
            </a>
            <a href="https://freetrial.dogopang.com/">
              <button className="border border-black bg-black text-white px-7 py-3 rounded-md hover:bg-gray-800">무료체험하기</button>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;