import React from 'react';
import backgroundImage from '../../assets/main01.gif';
import mobileBackgroundImage from '../../assets/main01_m.gif';

const AreaA = () => {
  return (
    <div
      className="relative flex justify-center items-center h-[680px] mt-7 bg-cover bg-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      />
      <div
        className="absolute inset-0 bg-cover bg-center md:hidden"
        style={{
          backgroundImage: `url(${mobileBackgroundImage})`,
        }}
      />
      <div className="relative w-full max-w-[350px] md:max-w-[1160px] md:px-8 -mt-80 md:mt-0 md:text-left">
        <h1 className="text-4xl md:text-5xl font-bold text-black mb-4">
          바둑이 즐거워진다<br />두고팡
        </h1>
        <p className="text-md md:text-lg text-gray-700 mb-6">
          교육현장의 선생님들이 직접 참여해
          <br />
          완성한 바둑교육과정을 담았습니다.
          <br />
          두고팡과 함께 즐거운 바둑여행을 떠나보아요.
        </p>
        <div className="flex space-x-4">
          <a href="https://freetrial.dogopang.com/" target="_blank" rel="noopener noreferrer">
            <button className="bg-black text-white text-sm md:text-base border border-black px-4 md:px-6 py-2 md:py-3 rounded-md hover:bg-gray-800">
              무료체험하기
            </button>
          </a>
          <a href="/service?tab=4">
            <button className="bg-white text-black text-sm md:text-base border border-black px-4 md:px-6 py-2 md:py-3 rounded-md hover:bg-gray-100">
              문의하기
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default AreaA;