import React from 'react';
import AreaA from '../components/teacher/AreaA';
import AreaB from '../components/teacher/AreaB';
import AreaC from '../components/teacher/AreaC';
import AreaD from '../components/teacher/AreaD';
import AreaE from '../components/teacher/AreaE';
import AreaF from '../components/teacher/AreaF';
import AreaG from '../components/teacher/AreaG';
import AreaH from '../components/teacher/AreaH';
import AreaI from '../components/teacher/AreaI';
import AreaJ from '../components/teacher/AreaJ';
import AreaK from '../components/teacher/AreaK';
import AreaL from '../components/homepage/AreaL';

const Teacher = () => {
  return (
    <div>
      <AreaA />
      <AreaB />
      <AreaC />
      <AreaD />
      <AreaE />
      <AreaF />
      <AreaG />
      <AreaH />
      <AreaI />
      <AreaJ />
      <AreaK />
      <AreaL />
    </div>
  );
};

export default Teacher;
