import React, { useState } from 'react';

const questions = [
  { id: 1, category: '서비스 전반', question: '두고팡는 무엇인가요?', answer: '바둑 공부 플랫폼입니다.' },
  { id: 2, category: '서비스 전반', question: '이용 대상은 누구인가요?', answer: '누구나.' },
  { id: 3, category: '서비스 전반', question: '무료체험이 가능한가요?', answer: '네, 가능합니다.' },
  { id: 4, category: '기기 이용', question: '모바일에서 이용 가능한가요?', answer: '네, 모바일에서도 가능합니다.' },
  { id: 5, category: '기기 이용', question: 'PC와 연동되나요?', answer: '네, PC와 연동됩니다.' },
  { id: 6, category: '기기 이용', question: '인터넷 연결이 필요한가요?', answer: '네, 필요합니다.' },
];

const Question = () => {
  const [activeQuestions, setActiveQuestions] = useState([]);

  const handleQuestionClick = (id) => {
    if (activeQuestions.includes(id)) {
      setActiveQuestions(activeQuestions.filter(q => q !== id));
    } else {
      setActiveQuestions([...activeQuestions, id]);
    }
  };

  // 그룹화된 카테고리별로 소제목을 표시합니다.
  const groupedQuestions = questions.reduce((acc, curr) => {
    if (!acc[curr.category]) {
      acc[curr.category] = [];
    }
    acc[curr.category].push(curr);
    return acc;
  }, {});

  return (
    <div className="space-y-8 max-w-[380px] md:max-w-[800px] mx-auto">
      {Object.keys(groupedQuestions).map((category) => (
        <div key={category}>
          <h2 className="text-xl font-bold text-yellow-500 mt-12 mb-6">{`[${category}]`}</h2>
          {groupedQuestions[category].map((q) => (
            <div key={q.id} className="border-b pb-6">
              <h3
                className={`text-lg mt-6 font-bold cursor-pointer ${
                  activeQuestions.includes(q.id) ? 'text-yellow-600' : 'text-[#333]'
                }`}
                onClick={() => handleQuestionClick(q.id)}
              >
                {q.question}
              </h3>
              <div
                className={`overflow-hidden transition-all duration-500 ease-in-out ${
                  activeQuestions.includes(q.id) ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'
                }`}
              >
                <p className="text-gray-600 mt-4">
                  {q.answer}
                </p>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Question;
