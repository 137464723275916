import React, { useEffect, useRef } from 'react';
import image from '../../assets/areag02.png'; // 단일 이미지 (해당 이미지 파일 경로로 수정)

const AreaG = () => {
  const ref1 = useRef(null);

  useEffect(() => {
    const currentRef1 = ref1.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // 이곳에 요소가 보일 때 실행될 코드 작성 가능
        }
      },
      {
        threshold: 0.3,
        rootMargin: '0px 0px -20px 0px',
      }
    );

    if (currentRef1) {
      observer.observe(currentRef1);
    }

    return () => {
      if (currentRef1) {
        observer.unobserve(currentRef1);
      }
    };
  }, []);

  return (
    <section
      className="relative flex flex-col md:flex-row items-center justify-between max-w-[2560px] h-[680px] mx-auto text-center bg-cover bg-center"
      style={{ backgroundColor: '#fff1da', marginTop: '-1px' }}
    >
      <div className="w-full mx-auto pt-24 md:pt-0 h-[330px] md:w-1/2 p-4 md:p-8 flex items-center justify-center">
        <div className="text-left">
          <span className="inline-block bg-[#f0c04e] text-white px-4 py-2 rounded-full text-sm font-bold mb-4">기기호환</span>
          <h2 className="text-2xl md:text-4xl font-bold mb-4">
            PC부터 모바일까지<br />자유로운 <span className="text-[#f0c04e]">기기호환</span>
          </h2>
          <p className="text-ms md:text-xl">
            집에서도 교실에서도<br />그리고 차로 이동하면서까지도<br />언제, 어디서나 편리하게 이용할 수 있어요.
          </p>
        </div>
      </div>

      <div className="w-full md:w-1/2 p-4 mb-16 md:mb-0 relative overflow-hidden">
        <div className="w-full h-[320px] md:h-[500px] relative">
          <img
            src={image}
            alt="기기호환 이미지"
            className="absolute top-0 left-0 w-full h-full object-contain transition-all duration-1000 ease-in-out transform opacity-100"
          />
        </div>
      </div>
    </section>
  );
};

export default AreaG;