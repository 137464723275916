import React, { useEffect, useRef, useState } from 'react';
import slideImage1 from '../../assets/teacher/areag01.png'; // 슬라이드 이미지 1
import slideImage2 from '../../assets/teacher/areag02.png'; // 슬라이드 이미지 2
import slideImage3 from '../../assets/teacher/areag03.png'; // 슬라이드 이미지 3

const AreaG = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  const textRef = useRef(null);
  const images = [slideImage1, slideImage2, slideImage3];

  useEffect(() => {
    const handleScroll = () => {
      const sectionTop = sectionRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (sectionTop < windowHeight * 0.8) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    let interval;
    if (isVisible) {
      interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 3000); // 3초마다 슬라이드 변경
    }
    return () => clearInterval(interval);
  }, [isVisible, images.length]);

  return (
    <section
      ref={sectionRef}
      className="relative flex flex-col md:flex-row items-center justify-between max-w-[2560px] h-[680px] mx-auto text-center bg-cover bg-center"
      style={{ backgroundColor: '#f9eff9', marginTop: '-1px' }}
    >
      <div
        ref={textRef}
        className={`w-full mx-auto pt-24 md:pt-0 h-[330px] md:w-1/2 p-4 md:p-8 flex items-center justify-center transition-opacity duration-1000 ease-out transform ${
          isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
        }`}
      >
        <div className="text-left">
            <span className="inline-block bg-[#905D94] text-white px-4 py-2 rounded-full text-sm font-bold mb-4">운영관리</span>
            <h2 className="text-2xl md:text-4xl font-bold mb-4">
                체계적인 바둑교실을<br/>만드는 <span className="text-[#905D94]">운영관리</span>
            </h2>
            <p className="text-ms md:text-xl">
                여러 클래스를 운영하는 선생님도<br/>LMS기능을 통해 학생 개별 맞춤관리와<br/>함께 체계적인 운영을 할 수 있어요.
            </p>
        </div>
      </div>

      <div className="w-full md:w-1/2 p-4 mb-16 md:mb-0 relative overflow-hidden">
        <div className="w-full h-[320px] md:h-[500px] relative">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slide ${index}`}
              className={`absolute top-0 left-0 w-full h-full object-contain transition-all duration-1000 ease-in-out transform ${
                index === currentIndex
                  ? 'translate-x-0 opacity-100'
                  : index < currentIndex
                  ? '-translate-x-full opacity-0'
                  : 'translate-x-full opacity-0'
              }`}
              style={{
                transition: 'transform 1s ease-in-out, opacity 1s ease-in-out',
              }}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default AreaG;