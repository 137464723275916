import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const InquiryForm = () => {
  const [formData, setFormData] = useState({
    subjectType: '일반 문의', // 기본 주제는 '일반 문의'
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubjectTypeChange = (type) => {
    setFormData({ ...formData, subjectType: type });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,   // EmailJS에서 제공받은 서비스 ID
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,  // EmailJS에서 제공받은 템플릿 ID
      formData,
      process.env.REACT_APP_EMAILJS_USER_ID       // EmailJS에서 제공받은 사용자 ID
    ).then((result) => {
        alert('문의가 성공적으로 전송되었습니다!');
      }, (error) => {
        console.error('전송 실패:', error.text);
      });
  };

  return (
    <form className="max-w-[380px] md:max-w-[500px] mx-auto mt-12" onSubmit={handleSubmit}>
      <div className="mb-4">
        <label className="block text-black font-bold text-lg mb-2">주제</label>
        <div className="flex space-x-2 mt-1">
          <button
            type="button"
            className={`px-4 py-2 rounded-md border ${
              formData.subjectType === '일반 문의' ? 'bg-yellow-500 text-white' : 'bg-white text-gray-700'
            }`}
            onClick={() => handleSubjectTypeChange('일반 문의')}
          >
            일반 문의
          </button>
          <button
            type="button"
            className={`px-4 py-2 rounded-md border ${
              formData.subjectType === '기능 문의' ? 'bg-yellow-500 text-white' : 'bg-white text-gray-700'
            }`}
            onClick={() => handleSubjectTypeChange('기능 문의')}
          >
            기능 문의
          </button>
        </div>
      </div>

      <div className="mb-4">
        <label htmlFor="name" className="block text-black font-bold text-lg mb-2">이름</label>
        <input
          type="text"
          id="name"
          name="name"
          placeholder="이름"
          className="mt-1 p-2 w-full border rounded"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-4">
        <label htmlFor="email" className="block text-black font-bold text-lg mb-2">이메일</label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="이메일"
          className="mt-1 p-2 w-full border rounded"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-4">
        <label htmlFor="phone" className="block text-black font-bold text-lg mb-2">휴대폰</label>
        <input
          type="text"
          id="phone"
          name="phone"
          placeholder="연락처"
          className="mt-1 p-2 w-full border rounded"
          value={formData.phone}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-4">
        <label htmlFor="subject" className="block text-black font-bold text-lg mb-2">제목</label>
        <input
          type="text"
          id="subject"
          name="subject"
          placeholder="제목"
          className="mt-1 p-2 w-full border rounded"
          value={formData.subject}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-4">
        <label htmlFor="message" className="block text-black font-bold text-lg mb-2">문의 내용</label>
        <textarea
          id="message"
          name="message"
          placeholder="문의 내용을 입력해 주세요."
          className="mt-1 p-2 w-full border rounded"
          rows="4"
          value={formData.message}
          onChange={handleChange}
          required
        />
      </div>
      <button
        type="submit"
        className="bg-yellow-500 text-white px-6 py-4 rounded w-full sm:w-2/3 mx-auto block text-center mb-12"
        >
        문의하기
      </button>
    </form>
  );
};

export default InquiryForm;
