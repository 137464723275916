import React from 'react';
import PayA from '../components/pay/PayA';
import PayB from '../components/pay/PayB';
const Pay = () => {
  return (
    <div>
      <PayA />
      <PayB />
    </div>
  );
};

export default Pay;