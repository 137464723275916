import React, { useState } from 'react';

const notices = [
  { id: 1, title: '6월 업데이트', date: '2024-06-10', content: '6월 업데이트되었습니다.' },
  { id: 2, title: '두고팡 OPEN!', date: '2024-05-17', content: '오픈되었습니다.' },
  { id: 3, title: '두고팡 OPEN!', date: '2024-05-17', content: '오픈되었습니다.' },
  { id: 4, title: 'SNS 솔직후기 이벤트', date: '2024-05-02', content: 'SNS에서 솔직후기 이벤트가 진행됩니다.' },
  { id: 5, title: '5월 업데이트', date: '2024-04-15', content: '5월 업데이트되었습니다.' },
  { id: 6, title: '기타 공지사항', date: '2024-04-10', content: '기타 공지사항이 있습니다.' },
  { id: 7, title: '기타 공지사항', date: '2024-04-10', content: '기타 공지사항이 있습니다.' },
  { id: 8, title: '기타 공지사항', date: '2024-04-10', content: '기타 공지사항이 있습니다.' },
  { id: 9, title: '기타 공지사항', date: '2024-04-10', content: '기타 공지사항이 있습니다.' },
  { id: 10, title: '기타 공지사항', date: '2024-04-10', content: '기타 공지사항이 있습니다.' },
  { id: 11, title: '기타 공지사항', date: '2024-04-10', content: '기타 공지사항이 있습니다.' },
  { id: 12, title: '기타 공지사항', date: '2024-04-10', content: '기타 공지사항이 있습니다.' },
  { id: 13, title: '기타 공지사항', date: '2024-04-10', content: '기타 공지사항이 있습니다.' },
  { id: 14, title: '기타 공지사항', date: '2024-04-10', content: '기타 공지사항이 있습니다.' },
  { id: 15, title: '기타 공지사항', date: '2024-04-10', content: '기타 공지사항이 있습니다.' },
  // 추가로 더 많은 공지사항들...
];

const Notice = () => {
  const [activeNotices, setActiveNotices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const handleNoticeClick = (id) => {
    setActiveNotices((prevActiveNotices) =>
      prevActiveNotices.includes(id)
        ? prevActiveNotices.filter((noticeId) => noticeId !== id)
        : [...prevActiveNotices, id]
    );
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentNotices = notices.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(notices.length / itemsPerPage);

  const handlePageClick = (direction) => {
    setCurrentPage((prevPage) => {
      let newPage = direction === 'next' ? prevPage + 1 : prevPage - 1;
      if (newPage < 1) newPage = 1;
      if (newPage > totalPages) newPage = totalPages;
      return newPage;
    });
  };

  return (
    <div className="max-w-[380px] md:max-w-[800px] mx-auto md:mx-auto mt-12 space-y-6">
      {currentNotices.map((notice) => (
        <div key={notice.id} className="border-b pb-4">
          <h3
            className={`text-sm md:text-xl font-bold cursor-pointer flex justify-between ${
              activeNotices.includes(notice.id) ? 'text-yellow-600' : 'text-[#333]'
            }`}
            onClick={() => handleNoticeClick(notice.id)}
          >
            <span className="mr-4 md:mr-20 tracking-[-0.1em] md:tracking-normal">{`No.${notice.id}`}</span>
            <span className="flex-1">{notice.title}</span>
            <span className="tracking-[-0.1em] md:tracking-normal text-gray-600">{notice.date}</span>
          </h3>
          <div
            className={`overflow-hidden transition-all duration-500 ease-in-out ${
              activeNotices.includes(notice.id) ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'
            }`}
          >
            <p className="text-xs md:text-base text-gray-600 mt-4">
              {notice.content}
            </p>
          </div>
        </div>
      ))}
      
      {/* Pagination */}
      <div className="flex justify-center mt-4 space-x-2">
        {/* Previous page button */}
        <button
            className="px-3 py-1 rounded bg-gray-200 text-gray-600"
            onClick={() => handlePageClick('prev')}
            disabled={currentPage === 1}
        >
            {'<'}
        </button>

        {/* Page number buttons */}
        {[...Array(totalPages)].map((_, index) => (
            <button
            key={index + 1}
            className={`px-3 py-1 rounded ${
                currentPage === index + 1 ? 'bg-yellow-500 text-white' : 'bg-gray-200 text-gray-600'
            }`}
            onClick={() => handlePageClick(index + 1)}
            >
            {index + 1}
            </button>
        ))}

        {/* Next page button */}
        <button
            className="px-3 py-1 rounded bg-gray-200 text-gray-600"
            onClick={() => handlePageClick('next')}
            disabled={currentPage === totalPages}
        >
            {'>'}
        </button>
      </div>
    </div>
  );
};

export default Notice;
