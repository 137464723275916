import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-100 py-8 text-gray-700">
      <div className="container mx-autopx-4 text-left max-w-[1024px] mx-auto">
        <div className="mb-6 ml-6 md:ml-8">
          <div className="font-bold text-lg">(주)두드림에듀</div>
          <div className="text-sm mt-2">
            <span className="block">대표 : 이우종</span>
            <span className="block">사업자 등록번호: 330-88-01117</span>
            <span className="block">대표전화: 070-7723-4696</span>
            <span className="block">사업장 주소지: 서울 송파구 중대로 197 3층 305호</span>
          </div>
          <div className="text-sm mt-2">
            <span className="block">© 2024 두고팡. All rights reserved.</span>
          </div>
        </div>
        <div className="flex space-x-4 text-sm mt-4 ml-6 md:ml-8">
          <a href="##" className="hover:text-gray-800">이용 약관</a>
          <a href="##" className="hover:text-gray-800">개인정보 처리 방침</a>
          <a href="##" className="hover:text-gray-800">저작권 안내</a>
          <a href="##" className="hover:text-gray-800">채용</a>
          {/* <a href="##" className="hover:text-gray-800">메뉴추가</a>*/}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
