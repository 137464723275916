import React, { useEffect, useRef, useState } from 'react';
import areal02 from '../../assets/areal02.png'; // 버튼 이미지
import areal03 from '../../assets/areal03.png'; // 오른쪽 이미지

const AreaL = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const currentSectionRef = sectionRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      {
        threshold: 0.3,
        rootMargin: '0px 0px -20px 0px',
      }
    );

    if (currentSectionRef) {
      observer.observe(currentSectionRef);
    }

    return () => {
      if (currentSectionRef) {
        observer.unobserve(currentSectionRef);
      }
    };
  }, []);

  return (
    <section
      ref={sectionRef}
      className="relative flex items-center justify-center w-full h-[80%] md:h-[500px] bg-cover bg-center py-12"
      style={{ backgroundColor: '#FCF8E3' }} // 배경색: #FCF8E3
    >
      <div
        className={`relative flex flex-col md:flex-row items-center justify-center max-w-[1160px] w-full h-full mx-auto px-4 transition-opacity duration-1000 ease-out transform ${
          isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
        }`}
      >
        <div className="flex flex-col items-center md:items-start text-left md:ml-12 px-4">
          <p className="bg-red-500 text-white font-extrabold text-md md:text-2xl mb-2 p-2 rounded">
            COMING SOON
          </p>
          <p className="text-xl md:text-2xl mb-2">
            창의력과 사고력을 키워주는
          </p>
          <h2 className="text-3xl md:text-5xl font-bold" style={{ lineHeight: '1.3em' }}>
            즐거운 바둑 플랫폼
            <br />
            <span className="text-[#DAA520]">두고팡</span> 앱 출시
          </h2>
          <p className="text-xs mb-2">
            (앱스토어 출시전까지 모바일에서는 Chrome App을 통해 이용해주세요)
          </p>
          <img
            src={areal02}
            alt="Google Play 버튼"
            className="mt-8 w-[150px] md:w-[200px] lg:w-[230px] h-auto cursor-pointer"
          />
        </div>

        <div className="relative w-full md:w-1/2 p-4 md:mb-0 overflow-hidden">
          <img
            src={areal03}
            alt="손어플"
            className="top-0 left-0 object-contain transition-all duration-1000 ease-in-out transform opacity-100"
          />
        </div>
      </div>
    </section>
  );
};

export default AreaL;