import React, { useEffect, useRef, useState } from 'react';
import areah01 from '../../assets/teacher/areah01.png';

const AreaH = () => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const currentRef = ref.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();  // 요소가 보이면 관찰 중지
        }
      },
      {
        threshold: 0.3,
        rootMargin: '0px 0px -20px 0px',
      }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <section
      ref={ref}
      className={`flex flex-col items-center justify-center py-24 px-8 text-center transition-opacity duration-1000 ease-out transform ${
        isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
      }`}
    >
      <span className="inline-block bg-[#915C95] text-white px-4 py-2 rounded-full text-sm font-bold mb-6">POINT 01</span>
      <h2 className="text-2xl md:text-4xl font-bold mb-8" style={{ lineHeight: 'calc(1em + 8px)' }}>
        다양한 유형을 조합해<br />
        <span className="text-[#915C95]">학습지</span> 만들기
      </h2>
      <p className="text-ms md:text-xl mb-8">
        선생님이 원하는대로 학습지를 만들어<br />
        출력하여 종이 테스트지로 직접 풀어보며<br />
        온라인학습과 시너지효과를 기대할 수 있어요.
      </p>
      
      <img
        src={areah01}
        alt="포인트 1"
        className="w-full max-w-[950px] h-auto mx-auto sm:max-w-[100%] md:max-w-[60%] lg:max-w-[60%] xl:max-w-[60%]"
      />
    </section>
  );
};

export default AreaH;