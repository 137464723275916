// x버튼 눌렀을때, 위로 슬라이딩되면서 없어지도록 변경해야함.

import React, { useState } from 'react';

const AdBanner = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="bg-yellow-700 flex justify-between items-center p-2" style={{ backgroundColor: '#F2C937', height: '50px' }}>
      <div className="flex-1 text-center">
        {/* 무료체험 링크 추가 */}
        <a href="##" className="text-white no-underline hover:underline">
          두고팡 무료체험 하기
        </a>
      </div>
      <button className="text-white" onClick={handleClose}>
        &#x2716;
      </button>
    </div>
  );
};

export default AdBanner;
