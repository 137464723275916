import React, { useEffect, useRef, useState } from 'react';
import areai01 from '../../assets/teacher/areai01.png';

const AreaI = () => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const currentRef = ref.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();  // 요소가 보이면 관찰 중지
        }
      },
      {
        threshold: 0.3,
        rootMargin: '0px 0px -20px 0px',
      }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <section
      ref={ref}
      className={`flex flex-col items-center justify-center py-24 px-8 text-center transition-opacity duration-1000 ease-out transform ${
        isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
      }`}
      style={{ backgroundColor: '#F1FAFF' }}  // 배경색 추가
    >
      <span className="inline-block bg-[#7BB0DF] text-white px-4 py-2 rounded-full text-sm font-bold mb-6">POINT 02</span>
      <h2 className="text-2xl md:text-4xl font-bold mb-8" style={{ lineHeight: 'calc(1em + 8px)' }}>
        선생님만의 <span className="text-[#7BB0DF]">특별한</span>
        <br/>
        <span className="text-[#7BB0DF]">커리큘럼</span>으로 교재제작
      </h2>
      <p className="text-ms md:text-xl mb-8 text-[#333333]"> {/* 기본 텍스트 색상 변경 */}
        선생님만의 특별한 커리큘럼이 있다면 보내주세요.<br />
        선생님 학생만을 위한 온라인 학습실을 지원하고<br />
        E-BOOK과 실물교재까지 제작이 가능해요.
      </p>
      
      <img
        src={areai01}
        alt="포인트 2"
        className="w-full max-w-[950px] h-auto mx-auto sm:max-w-[100%] md:max-w-[60%] lg:max-w-[60%] xl:max-w-[60%]"
      />
    </section>
  );
};

export default AreaI;