import React from 'react';
import AreaA from '../components/homepage/AreaA';  // AreaA 컴포넌트를 임포트
import AreaB from '../components/homepage/AreaB';
import AreaC from '../components/homepage/AreaC';
import AreaD from '../components/homepage/AreaD';
import AreaE from '../components/homepage/AreaE';
import AreaF from '../components/homepage/AreaF';
import AreaG from '../components/homepage/AreaG';
import AreaH from '../components/homepage/AreaH';
import AreaI from '../components/homepage/AreaI';
import AreaJ from '../components/homepage/AreaJ';
import AreaK from '../components/homepage/AreaK';
import AreaL from '../components/homepage/AreaL';

const Home = () => {
  return (
    <div>
      <AreaA />  {/* 최상단 컴포넌트 */}
      <AreaB />  {/* 창의력과 사고력 텍스트 */}
      <AreaC />  {/* 영상강의 */}
      <AreaD />  {/* 문제풀이 */}
      <AreaE />  {/* 실전학습 */}
      <AreaF />  {/* 레포트 */}
      <AreaG />  {/* 기기호환 */}
      <AreaH />  {/* Point1 */}
      <AreaI />  {/* Point2 */}
      <AreaJ />  {/* Point3 */}
      <AreaK />  {/* End */}
      <AreaL />
    </div>
  );
};

export default Home;
