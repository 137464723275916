import React, { useEffect, useRef, useState } from 'react';
import image from '../../assets/teacher/areaj01.png'; // 이미지 경로를 여기에 설정하세요.

const AreaJ = () => {
  const [isVisible, setIsVisible] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    const currentTextRef = textRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        threshold: 0.3, // 요소가 화면에 30% 이상 보일 때 애니메이션 트리거
      }
    );

    if (currentTextRef) {
      observer.observe(currentTextRef);
    }

    return () => {
      if (currentTextRef) {
        observer.unobserve(currentTextRef);
      }
    };
  }, []);

  return (
    <div
      className="relative flex flex-col justify-center items-center h-auto max-w-[1440px] mx-auto text-center py-24"
      style={{ backgroundColor: '#FAFAFA' }} // 배경색 추가
    >
      <div
        ref={textRef}
        className={`w-full px-8 opacity-0 transition-opacity duration-1000 ease-out transform ${
          isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
        }`}
      >
        <h2 className="text-5xl sm:text-2xl font-bold text-black leading-[60px] sm:leading-[35px]">
          학생과 <span className="text-[#915C95]">더 가까운</span> 바둑교실
          <br />
          수업이 <span className="text-[#915C95]">더 즐거운</span> 바둑교실
          <br />
          학부모님께 <span className="text-[#915C95]">더 믿음직한</span> 바둑교실
        </h2>
      </div>
      <div className="w-full flex justify-center items-center">
        <img
          src={image}
          alt="Your description"
          className="w-full max-w-[950px] h-auto mt-8"
        />
      </div>
    </div>
  );
};

export default AreaJ;