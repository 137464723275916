import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const QuestionFormPage = () => {
  const [formData, setFormData] = useState({
    organizationName: '',
    organizationType: '학교', // 기본 값 설정
    otherOrganizationType: '',
    name: '',
    email: '',
    phone: '',
    expectedUsers: '',
    additionalMessage: '',
    agreeToPrivacy: false,
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleOrganizationTypeChange = (type) => {
    setFormData({ ...formData, organizationType: type, otherOrganizationType: '' });
  };

  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.checked });
  };

  const isFormValid = () => {
    const {
      organizationName,
      organizationType,
      name,
      email,
      phone,
      expectedUsers,
      agreeToPrivacy,
    } = formData;

    // 모든 필드가 채워져 있고, 개인정보 동의가 체크되어야 유효
    return (
      organizationName &&
      organizationType &&
      name &&
      email &&
      phone &&
      expectedUsers &&
      agreeToPrivacy &&
      (organizationType !== '기타' || formData.otherOrganizationType)
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isFormValid()) {
      emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,   // EmailJS에서 제공받은 서비스 ID
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID_GROUP,  // EmailJS에서 제공받은 템플릿 ID
        {
          organization_name: formData.organizationName,
          organization_type: formData.organizationType !== '기타' ? formData.organizationType : formData.otherOrganizationType,
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          expected_users: formData.expectedUsers,
          additional_message: formData.additionalMessage,
        },
        process.env.REACT_APP_EMAILJS_USER_ID       // EmailJS에서 제공받은 사용자 ID
      ).then((result) => {
          alert('문의가 성공적으로 전송되었습니다!');
        }, (error) => {
          console.error('전송 실패:', error.text);
        });
    } else {
      alert('모든 필수 항목을 채워주세요.');
    }
  };

  return (
    <div className="max-w-[600px] mx-auto mt-12 p-4">
      <h1 className="text-3xl font-bold text-center mb-2">
        단체 도입 문의하기
      </h1>
      <p className="text-gray-700 mb-8 text-center">
        두고팡 프리미엄 이용을 위한 문의를 받고 있습니다.<br/>작성해주신 내용은 담당자에게 전달되어, 상담을 진행해 드릴 예정입니다.
      </p>

      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="organizationName" className="block text-black font-bold text-lg mb-2">단체 이름</label>
          <input
            type="text"
            id="organizationName"
            name="organizationName"
            placeholder="단체 이름"
            className="mt-1 p-2 w-full border rounded"
            value={formData.organizationName}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-black font-bold text-lg mb-2">단체 종류</label>
          <select
            className="mt-1 p-2 w-full border rounded"
            value={formData.organizationType}
            onChange={(e) => handleOrganizationTypeChange(e.target.value)}
            required
          >
            <option value="학교">학교</option>
            <option value="학원">학원</option>
            <option value="센터">센터</option>
            <option value="온라인">온라인</option>
            <option value="홈스쿨">홈스쿨</option>
            <option value="기업">기업</option>
            <option value="기타">기타</option>
          </select>
          {formData.organizationType === '기타' && (
            <input
              type="text"
              id="otherOrganizationType"
              name="otherOrganizationType"
              placeholder="단체 종류를 입력해주세요"
              className="mt-2 p-2 w-full border rounded"
              value={formData.otherOrganizationType}
              onChange={handleChange}
              required
            />
          )}
        </div>

        <div className="mb-4">
          <label htmlFor="name" className="block text-black font-bold text-lg mb-2">이름</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="이름"
            className="mt-1 p-2 w-full border rounded"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="email" className="block text-black font-bold text-lg mb-2">이메일</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="이메일"
            className="mt-1 p-2 w-full border rounded"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="phone" className="block text-black font-bold text-lg mb-2">연락처</label>
          <input
            type="text"
            id="phone"
            name="phone"
            placeholder="연락처"
            className="mt-1 p-2 w-full border rounded"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="expectedUsers" className="block text-black font-bold text-lg mb-2">예상 사용인원</label>
          <input
            type="text"
            id="expectedUsers"
            name="expectedUsers"
            placeholder="예상 사용인원"
            className="mt-1 p-2 w-full border rounded"
            value={formData.expectedUsers}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="additionalMessage" className="block text-black font-bold text-lg mb-2">기타 문의</label>
          <textarea
            id="additionalMessage"
            name="additionalMessage"
            placeholder="기타 문의 내용을 입력해주세요."
            className="mt-1 p-2 w-full border rounded"
            rows="4"
            value={formData.additionalMessage}
            onChange={handleChange}
          />
        </div>

        <div className="mb-4">
          <label className="block text-black font-bold text-lg mb-2">
            개인정보 수집 및 이용 동의
          </label>
          <div className="flex items-center">
            <input
              type="checkbox"
              id="agreeToPrivacy"
              name="agreeToPrivacy"
              className="mr-2"
              checked={formData.agreeToPrivacy}
              onChange={handleCheckboxChange}
              required
            />
            <label htmlFor="agreeToPrivacy">
              약관에 동의합니다.
            </label>
          </div>
          <p className="mt-2 text-gray-600 text-sm">
            [약관 내용 표시] 개인정보 수집 및 이용에 대한 동의가 필요합니다.
          </p>
        </div>

        <button
          type="submit"
          className={`bg-yellow-500 text-white px-6 py-4 rounded w-full sm:w-2/3 mx-auto block text-center mb-12 ${!isFormValid() && 'opacity-50 cursor-not-allowed'}`}
          disabled={!isFormValid()}
        >
          문의하기
        </button>
      </form>
    </div>
  );
};

export default QuestionFormPage;