import React, { useEffect, useRef, useState } from 'react';
import secondImage from '../../assets/teacher/areab01.png';


const AreaB = () => {
  const [isVisible, setIsVisible] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    const currentTextRef = textRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        threshold: 0.3, // 요소가 화면에 30% 이상 보일 때 애니메이션 트리거
      }
    );

    if (currentTextRef) {
      observer.observe(currentTextRef);
    }

    return () => {
      if (currentTextRef) {
        observer.unobserve(currentTextRef);
      }
    };
  }, []);

  return (
    <div
      className="relative flex flex-col justify-center items-center sm:h-[500px] md:h-[750px] max-w-[2560px] mx-auto bg-cover bg-center"
      style={{ backgroundColor: '#a15d97', marginTop: '-1px' }}
    >
      <div
        ref={textRef}
        className={`w-full px-8 text-center opacity-0 transition-opacity duration-1000 ease-out transform ${
          isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
        }`}
      >
        <h2 className="text-5xl sm:text-3xl font-bold text-black leading-[60px] sm:leading-[45px] mb-6 mt-20 md:mt-36">
          <span className="text-[#fff]">바둑이 재미있어지는 경험</span>
          <br/>
          우리 학생들에게 선물해보아요!
        </h2>
      </div>

      {/* 추가된 이미지 영역 */}
      <div className="flex justify-center items-center">
        <img src={secondImage} alt="Areab02" className="w-auto h-[90%] md:h-[85%]" />
      </div>
    </div>
  );
};

export default AreaB;