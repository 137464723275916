import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom'; // 쿼리 스트링을 읽기 위해 사용
import Question from './Question';
import Notice from './Notice';
import Guide from './Guide';
import InquiryForm from './Inquiry';

const tabs = [
  { id: 1, label: '자주 묻는 질문' },
  { id: 2, label: '공지사항' },
  { id: 3, label: '이용 가이드' },
  { id: 4, label: '1대1 문의' },
];  

const tabTitles = {
  1: '🙋🏻‍♂️ 자주 묻는 질문을 확인해주세요.',
  2: '📣 공지사항을 확인해주세요.',
  3: '📖 이용 가이드를 확인해주세요.',
  4: '❓ 1대1 문의를 남겨주세요.',
};

const ServiceTab = () => {
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    const tab = searchParams.get('tab');
    if (tab) {
      setActiveTab(Number(tab));
    }
  }, [searchParams]);

  const handleTabClick = (id) => {
    setActiveTab(id);
  };

  return (
    <div className="service-tab-container">
      <div className="max-w-[900px] mx-auto bg-[#f0c04e] text-white text-center py-[calc(2px+20px)] px-[calc(2px-15px)] mb-4">
        <h1 className="text-xl">{tabTitles[activeTab]}</h1>
      </div>
      <div className="tabs flex justify-around border-b">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={`text-sm md:text-2xl py-2 md:py-4 px-3 md:px-6 focus:outline-none ${
              activeTab === tab.id ? 'border-b-4 border-black font-bold' : 'text-gray-400'
            }`}
            onClick={() => handleTabClick(tab.id)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="content mt-4">
        {activeTab === 1 && <Question />} {/* Question 컴포넌트를 여기에 추가 */}
        {activeTab === 2 && <Notice />}  {/* Notice 컴포넌트를 여기에 추가 */}
        {activeTab === 3 && <Guide />}
        {activeTab === 4 && <InquiryForm />}
      </div>
    </div>
  );
};

export default ServiceTab;