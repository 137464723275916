import React, { useEffect, useRef, useState } from 'react';
import slideImage1 from '../../assets/areaf02.png'; // 슬라이드 이미지 1
import slideImage2 from '../../assets/areaf03.png'; // 슬라이드 이미지 2
import slideImage3 from '../../assets/areaf04.png'; // 슬라이드 이미지 3

const AreaF = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  const textRef = useRef(null);
  const images = [slideImage1, slideImage2, slideImage3];

  useEffect(() => {
    const handleScroll = () => {
      const sectionTop = sectionRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (sectionTop < windowHeight * 0.8) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    let interval;
    if (isVisible) {
      interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 3000); // 3초마다 슬라이드 변경
    }
    return () => clearInterval(interval);
  }, [isVisible, images.length]);

  return (
    <section
      ref={sectionRef}
      className="relative flex flex-col md:flex-row items-center justify-between max-w-[2560px] h-[680px] mx-auto text-center bg-cover bg-center"
    >
      <div className="flex flex-col-reverse md:flex-row w-full">
        <div className="w-full md:w-1/2 p-4 mb-16 md:mb-0 ml-0 md:ml-24 relative overflow-hidden">
          <div className="w-full h-[320px] md:h-[500px] relative">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Slide ${index}`}
                className={`absolute top-0 left-0 w-full h-full object-contain transition-all duration-1000 ease-in-out transform ${
                  index === currentIndex
                    ? 'translate-x-0 opacity-100'
                    : index < currentIndex
                    ? '-translate-x-full opacity-0'
                    : 'translate-x-full opacity-0'
                }`}
                style={{
                  transition: 'transform 1s ease-in-out, opacity 1s ease-in-out',
                }}
              />
            ))}
          </div>
        </div>

        <div
          ref={textRef}
          className={`w-full mx-auto pt-24 md:pt-0 h-[280px] md:h-[330px] md:w-1/2 p-4 md:p-8 ml-0 md:mr-12 mt-0 md:mt-24 flex items-center justify-center transition-opacity duration-1000 ease-out transform ${
            isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
          }`}
        >
          <div className="text-left">
            <span className="inline-block bg-[#f0c04e] text-white px-4 py-2 rounded-full text-sm font-bold mb-4">
              학습레포트
            </span>
            <h2 className="text-2xl md:text-4xl font-bold mb-4">
              레포트를 통해
              <br />
              <span className="text-[#f0c04e]">학습현황</span>과 <span className="text-[#f0c04e]">성취도</span> 확인
            </h2>
            <p className="text-ms md:text-xl">
              실시간 자동채점으로 학습결과가 반영된
              <br />
              레포트를 통해 취약유형을 극복할 수 있어요.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AreaF;