import React, { useEffect, useRef, useState } from 'react';
import areai01 from '../../assets/areai01.gif';
import areai02 from '../../assets/areai02.png';

const AreaI = () => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const currentRef = ref.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();  // 요소가 보이면 관찰 중지
        }
      },
      {
        threshold: 0.3,
        rootMargin: '0px 0px -20px 0px',
      }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <section
      ref={ref}
      className={`flex flex-col items-center justify-center py-24 px-8 text-center transition-opacity duration-1000 ease-out transform ${
        isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
      }`}
      style={{ backgroundColor: '#E3EEDF' }} // RGB 색상코드로 변경한 배경색
    >
      <span className="inline-block bg-[#58a05e] text-white px-4 py-2 rounded-full text-sm font-bold mb-6">
        POINT 02
      </span>
      <h2 className="text-2xl md:text-4xl font-bold mb-8" style={{ lineHeight: 'calc(1em + 8px)' }}>
        바둑을 더 배우고 싶으시다고요?
        <br />
        <span className="text-[#58a05e]">두고팡플러스+</span> 가 기다리고 있어요.
      </h2>
      <p className="text-ms md:text-xl mb-8 text-[#333333]"> {/* 기본 텍스트 색상 변경 */}
        교재별학습실, 문제은행 등
        <br />
        수만개의 문제를 통해 모든 바둑유형 완전 정복
      </p>
      
      {/* 첫 번째 중앙 정렬된 GIF 이미지 */}
      <img
        src={areai01}
        alt="설명 이미지 1"
        className="w-full max-w-[950px] h-auto mt-8 mx-auto sm:max-w-[90%] sm:scale-110 md:max-w-[80%] lg:max-w-[80%] xl:max-w-[80%]"
      />

      {/* 두 번째 중앙 정렬된 GIF 이미지 */}
      <img
        src={areai02}
        alt="설명 이미지 2"
        className="w-full max-w-[950px] h-auto mx-auto sm:max-w-[100%] md:max-w-[70%] lg:max-w-[70%] xl:max-w-[70%]"
      />
    </section>
  );
};

export default AreaI;