import React, { useState } from 'react';
import PayC from './PayC';
import PayE from './PayE';

const PayB = () => {
  const [activeTab, setActiveTab] = useState('tab1');

  const renderContent = () => {
    switch (activeTab) {
      case 'tab1':
        return <PayC />;
      case 'tab2':
        return <div className="-mb-8"><PayE /></div>;
      default:
        return null;
    }
  };

  return (
    <div className="w-full mx-auto py-8">
      <div className="flex justify-center space-x-32 border-b border-gray-300">
        <button
          onClick={() => setActiveTab('tab1')}
          className={`pb-2 text-lg font-bold ${
            activeTab === 'tab1' ? 'border-b-2 border-black text-black' : 'text-gray-500'
          }`}
        >
          학습권 안내
        </button>
        <button
          onClick={() => setActiveTab('tab2')}
          className={`pb-2 text-lg font-bold ${
            activeTab === 'tab2' ? 'border-b-2 border-black text-black' : 'text-gray-500'
          }`}
        >
          학원/단체문의
        </button>
      </div>
      <div className="mt-4">
        {renderContent()}
      </div>
    </div>
  );
};

export default PayB;