import React, { useEffect, useRef, useState } from 'react';
import backgroundImage from '../../assets/areab01.png';
import secondImage from '../../assets/areab02.png';

const AreaB = () => {
  const [isVisible, setIsVisible] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    const currentTextRef = textRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        threshold: 0.3, // 요소가 화면에 30% 이상 보일 때 애니메이션 트리거
      }
    );

    if (currentTextRef) {
      observer.observe(currentTextRef);
    }

    return () => {
      if (currentTextRef) {
        observer.unobserve(currentTextRef);
      }
    };
  }, []);

  return (
    <div
      className="relative flex flex-col justify-center items-center h-[680px] max-w-[2560px] mx-auto bg-cover bg-center"
      style={{backgroundImage: `url(${backgroundImage})`,}}
    >
      <div
        ref={textRef}
        className={`w-full px-8 text-center opacity-0 transition-opacity duration-1000 ease-out transform ${
          isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
        }`}
      >
        <h2 className="text-5xl sm:text-3xl font-bold text-black leading-[60px] sm:leading-[45px] mb-6">
          <span className="text-yellow-600">창의력</span>과 <span className="text-green-600">사고력</span>을 키워주는
          <br />
          즐거운 바둑 플랫폼
        </h2>
      </div>
      
      {/* 추가된 이미지 영역 */}
      <div className="flex justify-center items-center">
        <img src={secondImage} alt="Areab02" className="w-auto h-[80%] md:h-[100%]" />
      </div>
    </div>
  );
};

export default AreaB;