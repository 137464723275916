import React, { useEffect, useRef, useState } from 'react';
import backgroundImage from '../../assets/areaj01.png'; // PC용 배경 이미지
import mobileBackgroundImage from '../../assets/areaj01_m.png'; // 모바일용 배경 이미지
import image from '../../assets/areaj02.gif'; // 단일 이미지

const AreaJ = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const currentSectionRef = sectionRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      {
        threshold: 0.3,
        rootMargin: '0px 0px -20px 0px',
      }
    );

    if (currentSectionRef) {
      observer.observe(currentSectionRef);
    }

    return () => {
      if (currentSectionRef) {
        observer.unobserve(currentSectionRef);
      }
    };
  }, []);

  return (
    <section
      ref={sectionRef}
      className="relative flex flex-col md:flex-row items-center justify-between max-w-[2560px] h-[680px] mx-auto text-center bg-cover bg-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        marginTop: '-1px',
      }}
    >
      <div
        className="absolute inset-0 bg-cover bg-center md:hidden"
        style={{
          backgroundImage: `url(${mobileBackgroundImage})`,
        }}
      />
      <div
        className={`w-full mx-auto h-[330px] md:w-2/5 p-4 md:p-8 ml-0 md:ml-12 flex items-center justify-center transition-opacity duration-1000 ease-out transform ${
          isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
        }`}
      >
        <div className="text-left max-w-[1440px]">
          <h2 className="text-3xl md:text-5xl font-bold mb-4">
            <span className="text-[#58a05e]">학습능력</span>을
            <br />
            향상시켜주는 바둑
          </h2>
          <p className="text-ms md:text-xl">
            집중력, 논리력, 기억력 등
            <br />
            두뇌발달에 필요한 능력을 자극해요.
          </p>
        </div>
      </div>

      <div className="w-full md:w-3/5 p-4 mb-16 md:mb-0 relative overflow-hidden">
        <div className="w-full h-[320px] md:h-[500px] relative">
          <img
            src={image}
            alt="집중력논리력GIF"
            className="absolute top-0 left-0 w-full h-full object-contain transition-all duration-1000 ease-in-out transform opacity-100"
          />
        </div>
      </div>
    </section>
  );
};

export default AreaJ;