import React from 'react';
import backgroundImage from '../../assets/teacher/main01.png';
import mobileBackgroundImage from '../../assets/teacher/main01_m.png';

const AreaA = () => {
  return (
    <div
      className="relative flex justify-center items-center h-[680px] mt-7 bg-cover bg-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      />
      <div
        className="absolute inset-0 bg-cover bg-center md:hidden"
        style={{
          backgroundImage: `url(${mobileBackgroundImage})`,
        }}
      />
      <div className="relative w-full max-w-[350px] md:max-w-[1160px] md:px-8 -mt-80 md:mt-0 md:text-left">
        <h1 className="text-4xl md:text-5xl font-bold text-black mb-4">바둑수업이 특별해진다<br/>두고팡 프리미엄</h1>
        <p className="text-md md:text-lg text-gray-700 mb-6">
          더 나은 수업을 위한 선생님의 고민<br/>두고팡이 함께합니다.
        </p>
        <div className="flex space-x-4">
          <a href="https://freetrial.dogopang.com/" className="bg-black text-white text-sm md:text-base border border-black px-4 md:px-6 py-2 md:py-4 rounded-md hover:bg-gray-800">
            프리미엄 무료체험
          </a>
          <a href="/questionform" className="bg-white text-black text-sm md:text-base border border-black px-4 md:px-6 py-2 md:py-4 rounded-md hover:bg-gray-100">
            단체 도입문의
          </a>
        </div>
      </div>
    </div>
  );
};

export default AreaA;
