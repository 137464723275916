import React from 'react';
import ServiceTab from '../components/service/ServiceTab';

const Service = () => {
  return (
    <div className="container max-w-[900px] mx-auto p-4 mt-16">
      <ServiceTab />
    </div>
  );
};

export default Service;
