import React from 'react';

const PayA = () => {
  return (
    <div
      className="flex flex-col items-center justify-center py-24" // h-screen을 사용하여 전체 화면 높이를 채우도록 설정
      style={{ backgroundColor: '#FFF8E3' }} // 배경색을 설정
    >
      <h2 className="text-3xl md:text-4xl font-bold mt-3 mb-3 text-black text-center">
        이용요금
      </h2>
      <p className="text-lg text-black text-center">
        두고팡 상품을 소개합니다.
      </p>
    </div>
  );
};

export default PayA;